import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";

import lookUpService from "./../../../../Services/lookupApi";
import DynamicGrid from "../../../Common/DynamicGrid"
import Button from "@material-ui/core/Button";
import Modal from './../../../Common/Modal';

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */


const SelectQueryGridComp = class extends React.Component {
  constructor(props) {
    super(props);

    let data;
    try {
      //data = JSON.parse(props.component.jsonData);
      data = {}; //set default
    } catch (e) {
      data = {}; //set default
    }



    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handleButtonSelect = this.handleButtonSelect.bind(this);
    this.handleOnRadioSelect = this.handleOnRadioSelect.bind(this);
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this);
    this.handleUpdateValues = this.handleUpdateValues.bind(this);

    let value = props.value ? props.value : props.submissionData;
    this.state = {
      value: value,
      jsonData: value ? value.jsonData : data,
      headers: [],
      data: [
        // {
        //   A: "A1",
        // }, {
        //   A: "A2",
        // }
      ],
      showDialog: false
    };


  }

  async componentDidMount() {
    this.mounted = true;
    this.getData();

  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async getData() {

    if (!Boolean(typeof this.props.component.entityId === "number")) {
      return
    }

    const params = {
      entityId: this.props.component.entityId || -1,
      entityTypeId: this.props.component.entityTypeId || 9,
      frameworkId: this.props.component.frameworkId || 6,
      pentityId: this.props.component.pentityId || 1,
      pentityTypeId: this.props.component.pentityTypeId || 3,
      customGridKey: this.props.component.customGridKey,
      apikey: this.props.component.key,
      linkId: this.props.component.linkId || -1,
      linkFrameworkId: this.props.component.linkFrameworkId || -1,
      linkApiKey: this.props.component.linkApiKey || '',
    }
    const fulldata = await lookUpService.getQueryGridData(params);

    if (fulldata && this.mounted) {

      this.setState(
        {
          headers: [...fulldata.headers],
          data: [...fulldata.data]
        }
      )
    }
  }

  setValue = (jsonDataToSave) => {

    this.setState(
      (prevState) => ({
        value: {
          jsonData: jsonDataToSave,
        },
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };



  handleNameChange(e) {
    this.setState({
      value: e.target.value
    }, () => { this.props.onChange(e.target.value) })
  }

  handleRefresh(e) {
    e.preventDefault();
    this.getData();
  }

  handleButtonSelect(e) {
    e.preventDefault();
    this.setState({//clear selections when opening
      data: [...this.state.data.map(x => {
        x.selected = false
        return x;
      })]
    })
    this.handleDialog(true)
  }
  handleOnRadioSelect(item) {
    console.log(item)
    item.selected = true;
    // this.props.FORMIO_ROOT._submission.data.adminContainer.loggedInUserRole = "overriden";
    // this.props.FORMIO_ROOT._submission.data.auditreporting.executivesummary = "executivesummary";
    this.handleUpdateValues();
    this.handleDialog(false)
  }

  handleCheckboxSelect(e, item) {
    item.selected = e.target.checked;
    this.setState({
      data: [...this.state.data]
    })
  }

  handleUpdateValues() {
    try {

      //based on json mapping
      // const mappingObj = JSON.parse(this.props.component.mappingJson)
      // if (mappingObj) {
      //   Object.keys(mappingObj).map(columnKey => {
      //     const path = mappingObj[columnKey].split(".");
      //     const lastIndex = path.length - 1;
      //     path.reduce((obj, key, i) => {
      //       if (lastIndex === i) {
      //         obj[key] = item[columnKey];
      //       }
      //       else {
      //         obj = obj[key];
      //       }
      //       return obj;
      //     }, this.props.FORMIO_ROOT._submission)
      //   })
      // }
      let itemToPickValuesFrom = this.state.data.filter(x => x.selected);

      //gridColumn
      //mappingPath
      //gridColumnMapping
      const mappingColumns = this.props.component.gridColumnMapping;
      mappingColumns.map(mapperObj => {
        const path = mapperObj.mappingPath.split(".");
        const lastIndex = path.length - 1;
        path.reduce((obj, key, i) => {
          if (lastIndex === i) {
            if (mapperObj.joinMultiSelectionWith == "\\n") {//for new line array join doesnt work
              //console.log('NewLineJoin')
              var newvalue = "";
              itemToPickValuesFrom.map((x, index) => {
                if (index == 0)
                  newvalue = x[mapperObj.gridColumn];
                else
                  newvalue = `${newvalue}\n${x[mapperObj.gridColumn]}`
              })
              obj[key] = newvalue;
            }
            else {
              obj[key] = itemToPickValuesFrom.map(x => x[mapperObj.gridColumn]).join(mapperObj.joinMultiSelectionWith);
            }
            //obj[key] = "myValueReplaced";
          }
          else {
            let isArrayItem = false;
            let rowIndex = -1;
            if (key.indexOf('$$$') > -1) {//to handle datagrid assignments
              isArrayItem = true;//data.dataGrid[$$$].comments
              rowIndex = this.props.ROWINDEX;//pull the rowIndex from formio
              key = key.replace("[$$$]", "");
            }
            obj = obj[key];
            if (isArrayItem && rowIndex > -1) {
              obj = obj[rowIndex]
            }
          }
          return obj;
        }, this.props.FORMIO_ROOT._submission)
      })

      this.props.FORMIO_ROOT.triggerRedraw()

    }
    catch (e) {
      console.log('mappingError', e);
    }

  }


  handleDialog(openState) {
    if (!openState) {
      //update selected data
    }
    this.setState({ showDialog: openState })
  }

  render() {
    //const data = this.state.jsonData;
    //const { displayCards } = this.props;
    const tableStyle = {
      "borderWidth": "1px",
      'borderColor': "#aaaaaa",
      'borderStyle': 'solid',
      fontSize: 18
    }
    const disableComponent = this.props.component.disabled;
    return (
      <div>
        {/* <div
          style={{ display: "flex", margin: 20, justifyContent: "space-between" }}
        >
        </div> */}
        <div className="pageHeaderPanel">

          <div className="form-group">
            <label className="col-form-label">{this.props.component.label}:</label>
            <div className="input-group">
              <input className="form-control" value={this.state.value} onChange={this.handleNameChange} disabled={disableComponent}/>
              {!disableComponent &&<div className="input-group-append">
                <button className="btn btn-outline-primary" onClick={this.handleButtonSelect}><i className="fa fa-search" aria-hidden="true"></i></button>
              </div>}
            </div>
          </div>

          {
            this.state.showDialog && (
              <Modal closeModal={() => this.handleDialog(false)} title="Select Data"

                footerActions={[{ Name: "Confirm", className: "btn btn-secondary mr-3", callBack: () => { this.handleUpdateValues(false); this.handleDialog(false) } },
                { Name: "Cancel", className: "btn btn-primary", callBack: () => { this.handleDialog(false) } }
                ]}

              >
                <div className="page-header1">
                  <div class="btn-toolbar justify-content-end col-xs-12">
                    <div class="btn  btn-icon btn-outline-primary btnAction" onClick={this.handleRefresh} title="Refresh">
                      <i class="fas fa-refresh"></i>
                    </div>
                  </div>
                </div>
                {
                  this.state.headers.length > 0 &&
                  <DynamicGrid
                    Headers={this.state.headers}
                    Data={this.state.data}
                    DisplayCard={false}
                    ShowName={false}
                    AllowRadioSelection={this.props.component.AllowRadioSelection}
                    AllowCheckboxSelection={this.props.component.AllowCheckboxSelection}
                    HandleRadioSelect={this.handleOnRadioSelect}
                    HandleCheckboxSelect={this.handleCheckboxSelect}
                  ></DynamicGrid>
                }
              </Modal>
            )
          }



        </div>
        {/* <p>EntityId - {this.props.component.entityId}</p>
        <p>EntityTypeId - {this.props.component.entityTypeId}</p>
        <p>FrameworkId - {this.props.component.frameworkId}</p>
        <p>ParentEntityId - {this.props.component.pentityId}</p>
        <p>ParentEntityTypeId - {this.props.component.pentityTypeId}</p> */}
        {/* <p>linkId - {this.props.component.linkId}</p>
        <p>linkFrameworkId - {this.props.component.linkFrameworkId}</p>
        <p>linkApiKey - {this.props.component.linkApiKey}</p> */}
      </div >
    );
  }
};

export default class SelectQueryGrid extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "14SelectQueryGrid",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: SelectQueryGrid.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "selectQueryGrid",
      label: "Select Query Grid",
      logic: [
        {
          "name": "on Load",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateIds",
              "type": "mergeComponentSchema",
              //              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
              "schemaDefinition": "schema = {frameworkId:data.frameworkId, entityId:data.entityId,entityTypeId:data.entityTypeId,pentityId:data.pentityId, pentityTypeId:data.pentityTypeId, linkId:data.linkId, linkFrameworkId:data.linkFrameworkId, linkApiKey:data.linkApiKey }"
            }
          ]
        }
      ]
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }

    return ReactDOM.render(
      <SelectQueryGridComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        contextData={this._data}
        FORMIO_ROOT={this.root}
        ROWINDEX={this.rowIndex}
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data && this.element) {
      ReactDOM.render(
        <SelectQueryGridComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
          contextData={this._data}
          FORMIO_ROOT={this.root}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
