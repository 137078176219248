import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./edit";
import { CONFIG } from "./../../../../config/constant"

//import {browserHistory} from "react-router";

import lookUpService from "./../../../../Services/lookupApi";
import Typography from "@material-ui/core/Typography";

import Service from "../../../../Services";
import { toast } from "react-toastify";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Modal from './../../../Common/Modal';
import { red } from "@material-ui/core/colors";
import EntityInstanceForm from "../../../EntityInstance";


/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */

const LinkModes = {
  Existing: 1,
  New: 2,
  Copy: 3
}

const RelationType = {
  Link: "Link",
  Child: "Child"
}

const EntityLinkGridComp = class extends React.Component {
  constructor(props) {
    super(props);
    this.entityChild = React.createRef();
    let data;
    try {
      //data = JSON.parse(props.component.jsonData);
      data = {}; //set default
    } catch (e) {
      data = {}; //set default
    }


    this.handleMethChange = this.handleMethChange.bind(this)
    this.handleRegisterChange = this.handleRegisterChange.bind(this)
    this.handleConditionChange = this.handleConditionChange.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDialog = this.handleDialog.bind(this);
    this.handleAddLink = this.handleAddLink.bind(this);
    this.handleLinkMode = this.handleLinkMode.bind(this);
    this.handleEntityDialog = this.handleEntityDialog.bind(this);
    this.handleEntityView = this.handleEntityView.bind(this);

    this.handleLinkDelete = this.handleLinkDelete.bind(this);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleConfirmDialog = this.handleConfirmDialog.bind(this);

    let value = props.value ? props.value : props.submissionData;
    this.state = {
      value: value,
      jsonData: value ? value.jsonData : data,
      showDialog: false,
      // conditions: [{ name: "Select", id: -1 }, { name: "Contains", id: 1 }, { name: "Does not contain", id: 2 }, { name: "Starts With", id: 3 }],
      conditions: [{ name: "Select", id: -1 }, { name: "Contains", id: 1 }],
      meths: [],
      selectedMeth: -1,
      selectedCondition: -1,
      searchText: "",
      searchResults: [],
      linkMode: LinkModes.Existing,
      linkedData: {
        metaData: [],
        data: []
      },
      entitiesToLink: {
        metaData: [],
        data: []
      },
      selectedEntities:[],
      registers: [],
      selectedRegisterId: -1,
      selectedEntityToView: {},
      showEntityDialog: false,
      copyDependents: true,
      showConfirmDialog: false,
      confirmDialogPurpose:0,
      confirmHandler: () => { },
    };


  }

  async componentDidMount() {


    // this.setValue({ a: 1 })
    this.setValue({})
    if (!Boolean((Number(this.props.component.entityId) > 0)))
      return

    const params = {
      entityId: this.props.component.entityId,
      entityTypeId: this.props.component.entityTypeId,
      apikey: this.props.component.key,
      frameworkId: this.props.component.frameworkId
    }
    const list = await lookUpService.getLinkEntityFrameworkList(params);
    this.setState({
      meths: list,
      selectedMeth: list.length > 0 ? list[0].id : -1
    }, () => {
      this.getRegisters();
    })

    this.getLinkedData()
  }

  async getRegisters() {
    if (!(this.state.selectedMeth > 0))
      return;
    const params = {
      selectedMethId: this.state.selectedMeth,
      linkMode: this.state.linkMode
    }
    const list = await lookUpService.getRegisterListForFramework(params);
    this.setState({
      registers: list,
      selectedRegisterId: list.length > 0 ? list[0].id : -1
    })
  }

  async getLinkedData() {
    const listParams = {
      entityId: this.props.component.entityId,
      frameworkId: this.props.component.frameworkId,
      apikey: this.props.component.key
    }
    const linkedData = await lookUpService.getLinkedEntityList(listParams)
    if (Array.isArray(linkedData.data)) {
      linkedData.data.map(x => x.selected = false)
    }
    this.setState({ linkedData: linkedData })
  }

  setValue = (jsonDataToSave) => {

    this.setState(
      (prevState) => ({
        value: {
          jsonData: jsonDataToSave,
        },
      }),
      // () => this.props.onChange(null,this.state.value)
      () => this.props.onChange(this.state.value)
    );
  };


  handleSelect(item) {
    if (this.state.linkMode === LinkModes.Existing || this.state.linkMode === LinkModes.Copy) {
      item.selected = item.selected ? !item.selected : true;
      var selectedList = this.state.selectedEntities;
      if(Array.isArray(selectedList) && selectedList.filter((x)=>x.entityId === item.entityId).length ==0){
        selectedList.push(item);
      }
      this.setState({
        searchResults: this.state.searchResults,
        selectedEntities:selectedList
      })
    }
    else if (this.state.linkMode === LinkModes.New) {
      const list = [...this.state.searchResults]
      list.map(x => x.selected = false)
      item.selected = true
      this.setState({
        searchResults: list
      })
    }


  }
  handleMethChange(e) {
    this.setState({
      selectedMeth: e.target.value
    }, () => {
      this.getRegisters();
    });


  }
  handleRegisterChange(e) {
    this.setState({
      selectedRegisterId: e.target.value
    });
  }
  handleConditionChange(e) {
    this.setState({
      selectedCondition: e.target.value
    });
  }


  handleChange(e) {
    this.setState({
      searchText: e.target.value
    })
  }

  handleCheckboxChange(e) {

    this.setState({
      [e.target.name]: e.target.checked
    })
  }

  async handleAddLink(e) {
    if (e)
      e.preventDefault()

    if (this.state.linkMode === LinkModes.Existing || this.state.linkMode === LinkModes.Copy) {

      const itemsSelected = this.state.selectedEntities.filter(x => x.selected).map(x => {
        return {
          frameworkId: x.frameworkId,
          entityId: x.entityId
        }
      })



      console.log("itemsSelected", itemsSelected)
      if (Array.isArray(itemsSelected)) {
        const params = {
          entitiesToLink: itemsSelected,
          frameworkId: this.props.component.frameworkId,
          entityId: this.props.component.entityId,
          entityTypeId: this.props.component.entityTypeId,
          isCopy: this.state.linkMode === LinkModes.Copy ? 1 : 0,
          copyDependent: this.state.copyDependents ? 1 : 0,
          apikey: this.props.component.key
        }
        const list = await lookUpService.addLinkEntity(params);
        this.handleDialog(false)
        this.getLinkedData()
      }
    }
    else if (this.state.linkMode === LinkModes.New) {
      const itemsSelected = this.state.searchResults.filter(x => x.selected).map(x => { return x.registerId })
      if (Array.isArray(itemsSelected)) {
        //alert(itemsSelected.join(","))
        // const pentityTypeId = 3
        // const pentityid = 140
        if (itemsSelected.length > 0) {
          window.location.href = `/entity/9/-1/3/${itemsSelected[0]}/${this.props.component.entityId}/${this.props.component.frameworkId}/${this.props.component.key}`;
          this.handleDialog(false);
        }
        //browserHistory.push(`/entity/9}/-1/${pentityTypeId}/${pentityid}/${this.state.linkId}`);
      }
    }


  }

  async handleSearch(e) {
    if (e)
      e.preventDefault();


    if (this.state.linkMode === LinkModes.New) {
      const searchParams = {
        searchText: this.state.searchText,
        id: this.state.selectedMeth,
        conditionId: this.state.selectedCondition,
        apikey: this.props.component.key,
        pentityTypeId: this.props.component.pentityTypeId,
        pentityId: this.props.component.pentityId,
        frameworkId: this.props.component.frameworkId,
        isSameLevel: this.props.component.isSameLevel,
        configKey: this.props.component.configKey,
      }

      const list = await lookUpService.getLinkEntityRegisterList(searchParams);
      if (Array.isArray(list)) {
        const AutoSelect = list.length === 1 ? true : false;
        this.setState({
          searchResults: list.map(x => { x.selected = AutoSelect ? true : false; return x })
        },
          () => {
            if (AutoSelect) {
              this.handleAddLink(e);
            }
          })
      }
    }
    else if (this.state.linkMode === LinkModes.Existing || this.state.linkMode === LinkModes.Copy) {
      const searchParams = {
        searchText: this.state.searchText,
        id: this.state.selectedMeth,
        registerId: this.state.selectedRegisterId,
        conditionId: this.state.selectedCondition,
        apikey: this.props.component.key,
        isSameLevel: this.props.component.isSameLevel,
        configKey: this.props.component.configKey,
      }

      const dataToLink = await lookUpService.getEntityListForLinking(searchParams);
      if (Array.isArray(dataToLink.data)) {
        dataToLink.data.map(x => x.selected = false)
      }
      this.setState({ entitiesToLink: dataToLink })
    }


  }

  async handleDialog(openState) {
    this.setState({ showDialog: openState })
    if (openState && this.props.component.entityId > 0) {
      const params = {
        entityId: this.props.component.entityId,
        entityTypeId: this.props.component.entityTypeId,
        apikey: this.props.component.key,
        frameworkId: this.props.component.frameworkId
      }
      const list = await lookUpService.getLinkEntityFrameworkList(params);
      this.setState({
        meths: list
      })
      if (this.state.linkMode === LinkModes.New) {
        this.handleSearch()
      }
    }
  }

  handleLinkMode(mode) {
    this.setState({ 
      linkMode: mode,
      entitiesToLink: {
            metaData: [],
            data: []
        },
      selectedEntities:[], });
  }
  handleLinkDelete(params){
    this.setState(
      {
        showConfirmDialog: true,
        confirmDialogPurpose : 1,
        confirmHandler: async () => {          
          // console.log(params);
          const result = await Service().deleteSpecificLink(
            {
              linkId: params.LinkId
            }
          );
          if(result==true){
            toast.success("Deleted Successfully");          
          }
          
          this.state.showConfirmDialog=false;
          this.state.confirmDialogPurpose=0;
          this.getLinkedData();
        }
      });
  }
  handleEntityView(params) {

    // if (this.props.component.entityId > 0) {
    //   const params = {
    //     entityId: this.props.component.entityId,
    //     frameworkId: this.props.component.frameworkId
    //   }
    //   const list = await lookUpService.getLinkEntityFrameworkList(params);
    //   this.setState({
    //     meths: list
    //   })
    // }

    console.log(params)
    this.setState({
      selectedEntityToView: { entityTypeId: 9, entityId: params['entityId'], pentityTypeId: 3, pentityId: params['registerId'] }
    })
    this.handleEntityDialog(true);
  }

  handleEntityDialog(openState) {
    if (openState)
      this.setState({ showEntityDialog: openState })
    else {//while closing if the data is dirty ask for confirmation
      var isDataDirty = this.entityChild.current.isDataDirty();
      if (isDataDirty) {
        this.setState(
          {
            showConfirmDialog: true,
            confirmHandler: async () => {
              this.setState({
                showEntityDialog: false,
                showConfirmDialog: false
              })
            }
          })
      }
      else {
        this.setState({ showEntityDialog: false })
      }
    }
  }



  handleConfirmDialog(openState) {
    if (!openState) {
      this.setState({
        showConfirmDialog: openState,
        confirmHandler: () => { }
      })
    }

  }

  renderColumn(colname, rowData) {

    if (colname == "name")
      return <a onClick={(e) => { e.preventDefault(); this.handleEntityView(rowData) }} href="/" >{rowData[colname]}</a>;
    else if (rowData[colname + "_navurl"] && rowData[colname + "_navurl"].length > 0)
      return <a target={`${rowData[colname + "_navurlTarget"]}`} href={`${rowData[colname + "_navurl"]}`}>{rowData[colname]}</a>;
    else
      return rowData[colname];
  }
  render() {
    const data = this.state.jsonData;
    const disableComponent = this.props.component.disabled;
    const disbleButtons = disableComponent ? true : this.props.component.entityId > -1 ? false : true;
    const displayOnlyTopButtons = this.props.component.displayComponentAsLinkButton;
    const showEditActionOnGrid = this.props.component.showActionsInLinkedGridData;
    const tableStyle = {
      "borderWidth": "1px",
      'borderColor': "#aaaaaa",
      'borderStyle': 'solid',
      fontSize: 18
    }

    return (
      <div>
        <div>
          <div className="pageHeaderPanel">
            <div className="page-header1">

              {!displayOnlyTopButtons &&
                <h5>{this.props.component.relationType === RelationType.Link ? "Links" : "Children"}
                  {/* EntityTypeId- {this.props.component.entityTypeId} */}
                </h5>
              }


              <div className="btn-toolbar justify-content-end">
                {
                  this.props.component.showLinkExisting &&
                  this.props.component.relationType === RelationType.Link &&
                  (<Button
                    disabled={disbleButtons}
                    onClick={(e) => {
                      e.preventDefault()
                      this.handleLinkMode(LinkModes.Existing)
                      this.handleDialog(true)
                    }
                    } className="btn  btn-icon btn-outline-primary btnAction" title="Link Existing">
                    <i className="fa fa-link" aria-hidden="true"></i>
                  </Button>)
                } &nbsp;

                {
                  this.props.component.showCreateNewLinkButton &&
                  Number(this.props.component.entityTypeId) === CONFIG.EntityTypes.ENTITTY && (
                    <Button
                      disabled={disbleButtons}
                      onClick={(e) => {
                        e.preventDefault()
                        this.handleLinkMode(LinkModes.New)
                        this.handleDialog(true)
                      }
                      } className="btn  btn-icon btn-outline-primary btnAction" title="Create New &amp; Link">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </Button>
                  )
                }
                &nbsp;
                {
                  this.props.component.showCopyExistingButton &&
                  this.props.component.entityTypeId === CONFIG.EntityTypes.ENTITTY && (
                    <Button
                      disabled={disbleButtons}
                      onClick={(e) => {
                        e.preventDefault()
                        this.handleLinkMode(LinkModes.Copy)
                        this.handleDialog(true)
                      }
                      } className="btn  btn-icon btn-outline-primary btnAction" title="Copy Existing">
                      <i className="fa fa-copy"></i>
                    </Button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        {/* <p>EntityId - {this.props.component.entityId}</p>
        <p>EntityTypeId - {this.props.component.entityTypeId}</p>
        <p>FrameworkId - {this.props.component.frameworkId}</p> */}


        {this.state.linkedData && this.state.linkedData.data.length > 0 && (

          <div className="table-responsive fixtableWindow">
            <table className="table table-hover m-b-0">
              <thead>
                <tr className="bg-primary">
                  {
                    showEditActionOnGrid && <th width={30}>Actions</th>
                  }

                  {/* <th>Select</th> */}
                  {this.state.linkedData.metaData.map(header => (
                    !header.hidden && <th width={header.width}>{header.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.state.linkedData.data &&
                  this.state.linkedData.data.map((item, i) => (
                    <tr key={i}>
                      {showEditActionOnGrid &&
                        <td >
                          <button className="btn btn-icon btn-outline-info btnAction" 
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleLinkDelete(item);
                          }}>
                          <i className="fa fa-trash" aria-hidden="true"></i></button>
                        </td>
                      }
                      {/* <td >
                      <input
                        type="checkbox"
                        key={item.id}
                        checked={item.selected}
                        onChange={() => {
                          this.handleSelect(item);
                        }}
                      />
                    </td> */}
                      {this.state.linkedData.metaData.map(header => (
                        !header.hidden && <td width={item[`${header.width}`]}>{this.renderColumn(header.dbField, item)}</td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {this.state.showDialog && (<Modal closeModal={() => this.handleDialog(false)}>
          <div>
            <div className="">
              <div className="row">
                <div className="form-group col-md-2">
                  <span className="form-label" id="basic-meth">Frameworks:</span>
                  <select className="form-control"
                    value={this.state.selectedMeth} onChange={(e) => {
                      this.handleMethChange(e);
                    }}>
                    {this.state.meths.map(x =>
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    )
                    }
                  </select>
                </div>
                <div className="form-group col-md-2">
                  <span className="form-label">Registers:</span>
                  <select className="form-control" value={this.state.selectedRegisterId} onChange={(e) => {
                    this.handleRegisterChange(e);
                  }}>
                    {this.state.registers.map(x =>
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    )
                    }
                  </select>
                </div>
                <div className="form-group col-md-2">
                  <span className="form-label">Condition:</span>

                  <select className="form-control" value={this.state.selectedCondition} onChange={(e) => {
                    this.handleConditionChange(e);
                  }}>
                    {this.state.conditions.map(x =>
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    )
                    }
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <span className="form-label">Search Text:</span>
                  <div className="input-group">
                    <input type="text" className="form-control" value={this.state.searchText} onChange={this.handleChange} placeholder="Search Text" />

                    <div className="input-group-append">
                      <button onClick={this.handleSearch} type="button" className="btn btn-outline-secondary">
                        Search
                      </button>
                    </div>
                    {/* <p>  pentityId {this.props.component.pentityId} </p>
                    <p> pentityTypeId {this.props.component.pentityTypeId} </p>
                    <p> frameworkId {this.props.component.frameworkId} </p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                {this.state.linkMode === LinkModes.New &&
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-sm-1">&nbsp;</th>
                        <th className="col-sm-4">Register </th>
                        <th className="col-sm-3">Universe </th>
                        <th className="col-sm-4">UniversePath </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.searchResults &&
                        this.state.searchResults.map((item, i) => (
                          <tr key={i}>
                            <td>

                              {this.state.linkMode === LinkModes.Existing &&
                                <input
                                  className="form-control"
                                  type="checkbox"
                                  key={item.registerId}
                                  checked={item.selected}
                                  onChange={() => {
                                    this.handleSelect(item);
                                  }}
                                />}

                              {this.state.linkMode === LinkModes.New &&
                                <input
                                  type="radio"
                                  key={item.registerId}
                                  value={item.registerId}
                                  checked={item.selected}
                                  name="register"
                                  onChange={() => {
                                    this.handleSelect(item);
                                  }}
                                />}
                            </td>
                            <td> {item.register} </td>
                            <td> {item.Universe} </td>
                            <td> {item.UniversePath} </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                }
                {(this.state.linkMode === LinkModes.Existing || this.state.linkMode === LinkModes.Copy) &&
                  this.state.entitiesToLink && this.state.entitiesToLink.data.length > 0 && (
                    <div className="col-md-12">
                      <div className="table-responsive modelTable">
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              <th scope="col">Select</th>
                              {this.state.entitiesToLink.metaData.map(header => (
                                !header.hidden && <th scope="col">{header.label}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.entitiesToLink.data &&
                              this.state.entitiesToLink.data.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      key={item.id}
                                      checked={item.selected}
                                      onChange={() => {
                                        this.handleSelect(item);
                                      }}
                                    />
                                  </td>
                                  {this.state.entitiesToLink.metaData.map(header => (
                                    !header.hidden && <td>{this.renderColumn(header.dbField, item)}</td>
                                  ))}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                }
              </div>
              {this.state.selectedEntities &&
                this.state.selectedEntities.filter((e)=> e.selected).length>0 &&
                (<div className="row">
                  <div className="col-md-12">
                      <h5>Selected:</h5>
                      <div className="table-responsive modelTable">
                        <table className="table table-sm">                          
                          <thead>
                            <tr>
                              <th scope="col">Select</th>
                              {this.state.entitiesToLink.metaData.map(header => (
                                !header.hidden && <th scope="col">{header.label}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.selectedEntities.filter((e)=> e.selected).map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      key={item.id}
                                      checked={item.selected}
                                      onChange={() => {
                                        this.handleSelect(item);
                                      }}
                                    />
                                  </td>
                                  {this.state.entitiesToLink.metaData.map(header => (
                                    !header.hidden && <td>{this.renderColumn(header.dbField, item)}</td>
                                  ))}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                  {
                    this.props.component.showCopyDependentsButton &&
                    Number(this.props.component.entityTypeId) === CONFIG.EntityTypes.ENTITTY && (
                      <><input
                        type="checkbox"
                        name="copyDependents"
                        checked={this.state.copyDependents}
                        onChange={this.handleCheckboxChange
                        }
                      /> Copy Dependent Links
                      </>
                    )
                  }

                </div>
                <div className="col-md-6">
                  <div className="pull-right">
                    <button onClick={this.handleAddLink} className="btn btn-primary formio-button-add-row">
                      Add
                    </button>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </Modal>)}

        {
          this.state.showEntityDialog && this.state.selectedEntityToView.entityId > -1 && (
            <Modal closeModal={() => {

              this.handleEntityDialog(false)
            }}>

              {/* <h1>Alpha</h1> 
              */}
              {/* Need to find the best way to pass as url params */}
              <EntityInstanceForm match={{
                params: {
                  entityId: this.state.selectedEntityToView.entityId,
                  entityTypeId: this.state.selectedEntityToView.entityTypeId,
                  pentityTypeId: this.state.selectedEntityToView.pentityTypeId,
                  pentityId: this.state.selectedEntityToView.pentityId
                }
              }}
                location={{
                  key: (new Date()).getUTCMilliseconds()
                }}
                renderType="modalForm"
                handleModalDialog={(dlgState) => this.handleEntityDialog(dlgState)}
                ref={this.entityChild}
              >
              </EntityInstanceForm>

            </Modal>
          )
        }

        {//wip make this common component
          this.state.showConfirmDialog && (
            <Modal closeModal={() => this.handleConfirmDialog(false)} title="Warning"
              footerActions={[{ Name: "Confirm", className: "btn btn-secondary mr-3", callBack: () => { this.state.confirmHandler() } },
              { Name: "Cancel", className: "btn btn-primary", callBack: () => { this.handleConfirmDialog(false) } }
              ]}>
              {(this.state.confirmDialogPurpose==0) &&
              <p>Are you sure you want to proceed without saving the changes? You wil lose the data modified.</p>}
              {(this.state.confirmDialogPurpose==1) &&
              <p>Are you sure you want to Unlink?.</p>}
            </Modal>
          )
        }

      </div>
    );
  }
};


export default class EntityLinkGrid extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "8EntityLinkGrid",
      icon: "square",
      group: "basic",
      documentation: "",
      weight: -10,
      schema: EntityLinkGrid.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "entityLinkGrid",
      label: "Entity Link Grid",
      logic: [
        {
          "name": "on Load",
          "trigger": {
            "type": "javascript",
            "javascript": "result= true;"
          },
          "actions": [
            {
              "name": "populateIds",
              "type": "mergeComponentSchema",
              //              "schemaDefinition": "schema = { myWidgetURI:data[data.apiKeyToPick] }"
              "schemaDefinition": "schema = { entityId:data.entityId, entityTypeId:data.entityTypeId, frameworkId:data.frameworkId, pentityId:data.pentityId, pentityTypeId:data.pentityTypeId }"
            }
          ]
        }
      ]
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    //console.log('this.dataForSetting',this.dataForSetting)
    // if (this.shouldSetValue) {
    //   //this.setValue(this.dataForSetting);
    //   this.updateValue(this.dataForSetting);
    // }
    return ReactDOM.render(
      <EntityLinkGridComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
      />,
      element
    );
  }

  setValue(data) {
    console.log("Called wit data-", data);
    //hacked to rerender the react component by adding new prop //temp fix
    //hacked to rerender the react component by adding new prop submissionData//temp fix
    //TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO-TODO
    if (data && this.element) {
      ReactDOM.render(
        <EntityLinkGridComp
          component={this.component} // These are the component settings if you want to use them to render the component.
          value={this.dataValue} // updatedvalue of the component.
          onChange={this.updateValue} // The onChange event to call when the value changes.
          submissionData={data}
        />,
        this.element
      );
    }
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
