import axios from "axios";
import { toast } from "react-toastify";
export function requestInterceptor(req) {
  // `req` is the Axios request config, so you can modify
  // the `headers`.
  req.baseURL = process.env.REACT_APP_API_ROOT ? process.env.REACT_APP_API_ROOT : "http://localhost:5000/api";
  if (!req.url.includes("auth/login")) {
    const token = window.localStorage.getItem("token");
    req.headers.authorization = `bearer ${token}`;
    req.timeout = 60000; // Wait for 60 seconds before timing out
  }
  return req;
}

export function responseSuccessInterceptor(response) {
  let showToaster = false
  // if (response.config.url.includes("auth/login") || response.config.url.includes("/list/framework")) {
  //   showToaster = false
  // }
  if (typeof response.config.data === "string") {

    //const skipCalls = ["getLinkEntityFrameworkList","getLinkedEntityList","getFrameworkRoleList"]
    const showForCalls = ["mutation", "save", "create", "DeleteEntity","importDataForEntity"]
    let exists = showForCalls.find(x => response.config.data.indexOf(x) > -1)
    //TODO should have standard pattern to recognize errors
    try {
      if (response.config.data.indexOf('saveEntityDetailByType') > -1 &&
        response.data.data.Helper.saveEntityDetailByType.dataJson) {
        const result = JSON.parse(response.data.data.Helper.saveEntityDetailByType.dataJson);
        if (Array.isArray(result.Errors) && result.Errors.length > 0)
          exists = false;
      }
    } catch (e) {

    }
    if (exists) {
      showToaster = true;
    }
  }

  if (showToaster) {
    let showSuccess = true;
    let errMsg = "unknown Error";
    try {
      if (response.data.errors && response.data.errors.length > 0) {
        showSuccess = false;
        errMsg = response.data.errors[0].message
      }
    } catch (e) {

    }
    if (showSuccess)
      toast.success("success");
    else {
      toast.error(errMsg);
    }
  }

  return response;
}

export function responseErrorInterceptorWrapper() {

  let triggeredLogout = false;
  var responseErrorInterceptor = (err) => {
    if(triggeredLogout)
      return err;//this scenario occurs when multiple calls failed in parallel because of token expired
    if (err.response.status === 400) toast.error("Internal error occured");
    if (err.response.status === 401) {
      triggeredLogout = true;
      setTimeout(() => { triggeredLogout = false }, 2000);//reset after 2 sec to avoid multiple 401 calls
      toast.error("unauthorized.Please login again");
      if (typeof global.handleLogout == "function")
        global.handleLogout();
      //throw new axios.Cancel('Operation canceled as session expired.');
      return;//will not propagate
    }
    else toast.error("unknown Error");
    return err;
  }
  return responseErrorInterceptor
}



// axios.interceptors.response.use((response) => {

//   toast.success("success");
//   return response;
// },
// (err) => {
//   if (err.response.status === 400) toast.error("Internal error occured");
//   if (err.response.status === 401) toast.error("unauthorized.Please login again");
//   else toast.error("unknown Error");
//   return err;
// }
// );
