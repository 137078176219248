import GraphqlWrapper from "./graphql";

const { Graphql } = GraphqlWrapper


const findParent = (arr, toEntFrmId) => {
  var fromLinkId = toEntFrmId;
  if (fromLinkId.split("-").length > 0) {
    fromLinkId = fromLinkId.split("-")[0];
  }
  return arr.find((parent) => parent.LinkId == fromLinkId);
}

const createTreeNode = (value) => {
  return {
    label: (value.LinkReference == "0") ? value.FromEntityname : value.ToEntityname,
    value: value.ToFrmEntID,
    _data: value,
    disabled: (value.LinkReference == "0") ? true : false,
    checked: (value.LinkedID > 0),
    children: (value.children !== undefined)
      ? value.children.map(createTreeNode)
      : undefined
  };
}

const getTreeFromArray = (data) => {
  return data
    // first restructure existing nodes as a tree
    .reduce((result, value, index, originalArray) => {
      if (value.LinkReference && value.LinkReference !== "0") {
        const parent = findParent(originalArray, value.LinkReference);

        if (parent) {
          // add as child if has parent
          parent.children = (parent.children || []).concat(value);
        }

        return result;
      } else {
        // Add value to top level of the result array
        return result.concat(value);
      }
    }, [] /* Initialize with empty result array */)
    // them map to new data type
    .map(createTreeNode);
}

const apis = {
  getFrameworkList: async () => {
    var result = await Graphql(`{
      Helper {
              getFrameworkList{
                name,
                frameworkid,
                fullschemajson
              }
          }      
        }`);
    console.log("result--->>>>>>>>>>>>>", result);
    const tranform = result.data.Helper.getFrameworkList.map((x) => {
      return {
        name: x.name,
        meth: x.fullschemajson,
        id: x.frameworkid,
      };
    });
    return tranform;
  },

  getEntityList: async (exists) => {
    var result = await Graphql(`{
      Helper {
          getEntityList{
            EntityTypeId
            Name
            InAdminForm
            IsMeth
          }
          }      
        }`);
    console.log("result--->>>>>>>>>>>>>", result);
    if (result) {
      const tranform = result.data.Helper.getEntityList
        .filter((x) => x.InAdminForm == exists)
        .map((x) => {
          return {
            name: x.Name,
            id: x.EntityTypeId,
          };
        });
      return tranform;
    }
    return null
  },

  getConfigFormsList: async (exists) => {
    var result = await Graphql(`{
      Helper {
        getConfigFormsList{
            EntityTypeId
            Name
            InAdminForm
            IsMeth
          }
          }      
        }`);
    console.log("result--->>>>>>>>>>>>>", result);
    if (result) {
      const tranform = result.data.Helper.getConfigFormsList
        .filter((x) => x.InAdminForm == exists)
        .map((x) => {
          return {
            name: x.Name,
            id: x.EntityTypeId,
          };
        });
      return tranform;
    }
    return null
  },

  addFramework: async (name, meth, fullSchema) => {
    var result = await Graphql(`mutation{
      createFramework(name:"${name}",inputJson:"""${meth}""",fullShemaJSON:"""${fullSchema}""", userCreated:2)
    }`);
    if (result.errors) {
      alert(result.errors[0].message);
      return;
    }
    return result;
  },

  addAdminEntity: async (params) => {
    var result = await Graphql(`mutation{
      Helper {
        createAdminForms(entityTypeId:${params.entityTypeId},formJson:"""${params.formJSON}""",
         entityId:${params.id},
         userCreated:2)
      }
    }`);
    if (result.errors) {
      alert(result.errors[0].message);
      return;
    }
    return result.data.Helper.createAdminForms;
  },

  getAdminEntityBuilder: async (id) => {
    var result = await Graphql(`{
      Helper {
        getAdminEntityBuilder(id:${id}){
          EntityTypeId
          Name
          FormJson
          }
          }      
        }`);
    console.log("result--->>>>>>>>>>>>>", result);
    const tranform = result.data.Helper.getAdminEntityBuilder[0];
    return {
      entityName: tranform.Name,
      id: tranform.EntityTypeId,
      formJSON: JSON.parse(tranform.FormJson),
    };
  },

  getAdminEntityInstance: async (id) => {
    var result = await Graphql(`{
      Helper {
        getAdminEntityInstance(id:${id}){
            AGSID
            Name
            FormJson
            DataJson
          }
          }      
        }`);
    console.log("result--->>>>>>>>>>>>>", result);
    const tranform = result.data.Helper.getAdminEntityInstance;
    return {
      entityName: tranform.Name,
      id: tranform.AGSID,
      formJSON: JSON.parse(tranform.FormJson),
      dataJSON: JSON.parse(tranform.DataJson),
    };
  },

  saveAdminEntityInstance: async (params) => {
    var result = await Graphql(`mutation{
      Helper {
      SaveAdminEntityInstance(id:${params.id}
                  ,dataJSON:"""${params.dataJSON}"""
                  ,userCreated:2)
      }
    }`);
    if (result.errors) {
      alert(result.errors[0].message);
      return;
    }
    return result.data.Helper.SaveAdminEntityInstance;
  },

  saveAdminFormEntityList: async (params) => {
    var result = await Graphql(`mutation{
      Helper {
        saveAdminFormEntityList(entityTypeId:${params.entityTypeId}
                  ,formJson:"""${params.formJSON}"""
                  ,schemaForDB:"""${params.schemaForDB}"""
                  ,userloginId:2)
      }
    }`);
    if (result.errors) {
      alert(result.errors[0].message);
      return;
    }
    return result.data.Helper.saveAdminFormEntityList;
  },

  saveUserDetail: async (params) => {
    var result = await Graphql(`mutation{
      Helper {
        saveUserDetail(userId:${params.id}
                  ,dataJSON:"""${params.dataJSON}"""
                  ,userLoginId:2)
      }
    }`);
    if (result) {
      return result.data.Helper.saveUserDetail;
    }
    return null;
  },

  //role/user/group detail save
  saveEntityDetail: async (params) => {
    var result = await Graphql(`mutation{
      Helper {
        saveEntityDetail(entityId:${params.id}
                  ,dataJSON:"""${params.dataJSON}"""
                  ,entityTypeId:${params.entityTypeId},
                  ,additionalData:"""${params.additionalData}"""
                  ,userLoginId:2)
      }
    }`);
    if (result) {
      return result.data.Helper.saveEntityDetail;
    }
    return null;
  },

  getEntitiesListByEntityTypeId: async (params) => {
    var result = await Graphql(`{
      Helper {
        getEntitiesListByEntityTypeId(entityTypeId:${params.entityTypeId})
                  {
                    dataJson
                  }
      }
    }`);
    if (result) {
      const tranform = result.data.Helper.getEntitiesListByEntityTypeId;
      return JSON.parse(tranform.dataJson);
    }
    return null;
  },

  getFrameworkDetails: async (id) => {
    var result = await Graphql(`{
      Helper {
        getFrameworkDetails(frameworkId:${id}){
          fullschemajson
          name
          frameworkid  
          }
          }      
        }`);
    console.log("result--->>>>>>>>>>>>>", result);
    const tranform = result.data.Helper.getFrameworkDetails[0];
    return {
      name: tranform.name,
      id: tranform.frameworkid,
      meth: tranform.fullschemajson,
    };
  },

  getEntitiesDetailByEntityTypeId: async (entityTypeId, entityId) => {
    var result = await Graphql(`{
      Helper {
        getEntitiesDetailByEntityTypeId(entityTypeId:${entityTypeId},entityId:${entityId}){
          entityId
          dataJSON
          formJSON
          Name
          Description
          apikey
          apiKeyRequired
          }
          }      
        }`);
    console.log("result--->>>>>>>>>>>>>", result);
    const tranform = result.data.Helper.getEntitiesDetailByEntityTypeId;
    return {
      name: tranform.Name,
      description: tranform.Description,
      apikey: tranform.apikey,
      apiKeyRequired: tranform.apiKeyRequired,
      id: tranform.entityId,
      formJSON: JSON.parse(tranform.formJSON),
      dataJSON: JSON.parse(tranform.dataJSON) //todo load the dataJson from SP
    };
  },

  saveAdminUniverse: async (params) => {
    var result = await Graphql(`mutation{
      saveAdminUniverse(id:${params.id},formJson:"""${params.formJson}""",schemaForDB:"""${params.schemaForDB}""", userloginId:2)
    }`);
    if (result.errors) {
      alert(result.errors[0].message);
      return;
    }
    return result;
  },

  //use for universe
  getDetailByEntityIdAndType: async (entityTypeId, entityId, pentityTypeId, pentityId) => {
    var result = await Graphql(`{
      Helper {
        getDetailByEntityIdAndType(entityTypeId:${entityTypeId},entityId:${entityId},
          pentityTypeId:${pentityTypeId},pentityId:${pentityId}){
          entityId          
          formJSON
          dataJSON
          }
          }      
        }`);
    console.log("result--->>>>>>>>>>>>>", result);
    const tranform = result.data.Helper.getDetailByEntityIdAndType;
    return {
      //name: tranform.Name,
      id: tranform.entityId,
      formJSON: JSON.parse(tranform.formJSON),
      dataJSON: JSON.parse(tranform.dataJSON) //todo load the dataJson from SP
    };
  },

  saveEntityDetailByType: async (params) => {
    var result = await Graphql(`mutation{
      Helper {
        saveEntityDetailByType(entityId:${params.id}
                  ,dataJSON:"""${params.dataJSON}"""
                  ,entityTypeId:${params.entityTypeId}
                  ,pentityId:${params.pid}
                  ,pentityTypeId:${params.pentityTypeId}
                  ,linkEntityId:${params.linkEntityId}
                  ,linkFramweworkId:${params.linkFramweworkId}
                  ,linkApiKey:"${params.linkApiKey}",
                  ,workflowStateId:"${params.workflowStateId}",
                  ,userLoginId:2)
                  {
                    id
                    dataJson
                  }
      }
    }`);
    if (result) {
      return result.data.Helper.saveEntityDetailByType;
    }
    return null;
  },

  getListByEntityIdAndType: async (entityTypeId, entityId, pentityTypeId, pentityId, viewId, filtersData,
   pageNumber=-1,
    rowsPerPage=-1,
    sortByColumnId="",
    sortOrder="ASC"
    ) => {

    let filtersDataObject = {
      matchCondition: filtersData.matchCondition,
      filters: filtersData.filters,
      currentUser: filtersData.currentUser,
      topRecords: filtersData.topRecords,
      orderByColumn: filtersData.orderByColumn,
      sortBy: filtersData.sortBy,
    }


    var result = await Graphql(`{
      Helper {
        getListByEntityIdAndType(entityTypeId:${entityTypeId},entityId:${entityId},
          pentityTypeId:${pentityTypeId},pentityId:${pentityId},viewId:${viewId},
          filtersData:"""${JSON.stringify(filtersDataObject)}""",
          pageNumber:${pageNumber},rowsPerPage:${rowsPerPage},
          sortByColumnId:"${sortByColumnId}",sortOrder:"${sortOrder}")
          {
            additionalDataJson,
            data {
              name,
              entityId,
              entityTypeId,
              pentityId,
              pentityTypeId,
              read,
              write,
              modify,
              cut,
              copy,
              delete,
              export,
              children {
                name,
               entityId,
               entityTypeId,
               pentityId,
               pentityTypeId,
               count,
               navListPath
              }
            }
            }
           
          }      
        }`);
    console.log("result--->>>>>>>>>>>>>", result);
    const tranform = result.data.Helper.getListByEntityIdAndType;
    return tranform;

  },

  getFilterDataByEntityIdAndType: async (entityTypeId, entityId, pentityTypeId, pentityId, viewId, frameworkId) => {

    var result = await Graphql(`{
      Helper {
        getFilterDataByEntityIdAndType(entityTypeId:${entityTypeId},entityId:${entityId},
          pentityTypeId:${pentityTypeId},pentityId:${pentityId},viewId:${viewId},frameworkId:${frameworkId ? frameworkId : -1}){
            dataJson,            
            }           
          }      
        }`);
    const tranform = result.data.Helper.getFilterDataByEntityIdAndType;
    return JSON.parse(tranform.dataJson);

  },

  getCalculatedValueForEntity: async (params) => {

    var result = await Graphql(`{
      Helper {
        getCalculatedValueForEntity(
                      elementKey:"${params.elementKey}"
                      ,frameworkId:${params.frameworkId}
                      ,entityId:${params.entityId}
                      ,entityTypeId:${params.entityTypeId}
                      ,pentityId:${params.pentityId}
                      ,pentityTypeId:${params.pentityTypeId}
                      ){
                dataJson
              }
          }      
        }`);
    const tranform = result.data.Helper.getCalculatedValueForEntity
    return JSON.parse(tranform.dataJson).value;

  },

  getCustomTypeList: async () => {
    var result = await Graphql(`{
      Helper {
        getCustomTypeList{            
            name            
            id
          }
          }      
        }`);
    console.log("result--->>>>>>>>>>>>>", result);
    if (result) {
      const list = result.data.Helper.getCustomTypeList;

      return list;
    }
    return null
  },

  getCustomFormBuilderListByTypeId: async (typeId) => {
    var result = await Graphql(`{
      Helper {
        getCustomFormBuilderListByTypeId(typeId:${typeId}) {            
            name            
            id
          }
          }      
        }`);
    if (result) {
      const list = result.data.Helper.getCustomFormBuilderListByTypeId;

      return list;
    }
    return null
  },


  saveCustomFormBuilder: async (params) => {
    const additionalDataString= JSON.stringify(params.additionalData)
    var result = await Graphql(`mutation{
      Helper {
      saveCustomFormBuilder(id:${params.id},typeId:${params.typeId},formJson:"""${params.formJson}""",schemaForDB:"""${params.schemaForDB}"""
      ,name:"${params.name}",apikey:"${params.apikey}",description:"${params.description}",
      additionalData:"""${additionalDataString}"""
      )
      }
    }`);
    if (result.errors) {
      alert(result.errors[0].message);
      return;
    }
    return result.data.Helper.saveCustomFormBuilder;
  },

  saveCustomView: async (params) => {
    var result = await Graphql(`mutation{
      Helper {
        saveCustomView(
                  dataJSON:"""${params.dataJSON}"""
                  )
      }
    }`);
    if (result) {
      return result.data.Helper.saveCustomView;
    }
    return null;
  },
  getDynamicTableData: async (ctxData) => {
    console.log("ctxData-get",ctxData);
    var result = await Graphql(`{
      Helper {
        GetDynamicTableDataInEntity(
                  contextData:"""${JSON.stringify(ctxData)}"""
                )
                {
                  dataJson
                }
      }
    }`);
    if (result) {
      var tableData = JSON.parse(result.data.Helper.GetDynamicTableDataInEntity.dataJson);
      return { 
        cfgData:JSON.parse(tableData[0].cfgData),
        tData: JSON.parse(tableData[0].tData)
      };
    }
    return {cfgData:[],tData:[]};


    
    // return { 
    //         cfgData:[{colid:0,title:"id",type:"int"},{colid:1,title:"name",type:"string"},{colid:2,title:"description",type:"string"}],
    //         tData: [{ id: 1, name: "Sample1", description: "Desc1" },{ id: 2, name: "Sample2", description: "Desc2" }] 
    //       };


  },

  GetCustomQueryData: async (ctxData) => {
    console.log("ctxData-get",ctxData);
    var result = await Graphql(`{
      Helper {
        GetCustomQueryData(
                  contextData:"""${JSON.stringify(ctxData)}"""
                )
                {
                  dataJson
                }
      }
    }`);
    if (result) {
      var tableData = JSON.parse(result.data.Helper.GetCustomQueryData.dataJson);
      return tableData;
    }
    return [];
  },

  saveDynamicTableData: async (ctxData, saveData) => {
    console.log("saveDynamicTableData.ctxData", ctxData);
    console.log("saveDynamicTableData.saveData", saveData);
    var saveResult = await Graphql(`{
      Helper {
        SaveDynamicTableDataInEntity(
                  contextData:"""${JSON.stringify(ctxData)}""",
                  saveData:"""${JSON.stringify(saveData)}"""
                )
                {
                  dataJson
                }
      }
    }`);
    if (saveResult) {
      return saveResult.data.Helper.SaveDynamicTableDataInEntity;
    }
    return null;
  },

  getCustomTreeViewData: async (params) => {

    // apikey: "selectAuditComponents"
    // entityId: 1
    // entityTypeId: 9
    // frameworkId: 2
    // pentityId: 3
    // pentityTypeId: 3

    // (entityId:${params.entityId},  
    // entityTypeId:${params.entityTypeId},
    // frameworkId:${params.frameworkId > -1 ? params.frameworkId : -1}, 
    // apikey:"${params.apikey})",

    var result = await Graphql(`{
      Helper {
        GetLinkedEntitiestoCopy(
            entityId:${params.entityId},  
            entityTypeId:${params.entityTypeId},
            parentEntityId:${params.pentityId > -1 ? params.pentityId : -1}, 
            parentEntitytypeid:${params.pentityTypeId > -1 ? params.pentityTypeId : -1}, 
            apikey:"${params.apikey}",
          )
          {
              LinkId, 
              FromEntityId,  
              FromFrameworkId,  
              FromEntityname,
              ToFrameWorkId,  
              ToEntityid,  
              ToEntityname,
              FromFrmEntID,
              ToFrmEntID, 
              LinkedID,
              LinkReference 
            }
          }      
        }`);


    const linkedData = result.data.Helper.GetLinkedEntitiestoCopy;
    var linkedTreeData = getTreeFromArray(linkedData);
    var selectedNodes = linkedData.filter((node) => {
      node.label = (node.LinkReference == "0") ? node.FromEntityname : node.ToEntityname;
      node.Name = (node.LinkReference == "0") ? node.FromEntityname : node.ToEntityname;
      return node.LinkedID > 0;
    })
    return { "treeData": linkedTreeData, "selectedData": selectedNodes };
  },

  saveCustomTreeViewData: async (ctxData, saveData) => {
    console.log("saveCustomTreeViewData.ctxData", ctxData);
    console.log("saveCustomTreeViewData.saveData", saveData);

    var saveResult = await Graphql(`{
      Helper {
        CopyLinkedEntitiesFroLibrary(
                  contextData:"""${JSON.stringify(ctxData)}""",
                  saveData:"""${JSON.stringify(saveData)}"""
                )
                {
                  dataJson
                }
      }
    }`);
    if (saveResult) {
      return saveResult.data.Helper.saveEntityDetailByType;
    }
    return null;
  },

  setUserDefaultViewForRegister: async (params) => {

    var result = await Graphql(`mutation{
      Helper {
        SetUserDefaultViewForRegister(
                  dataJSON:"""${JSON.stringify(params)}"""
                  )
                  {
                    dataJson
                  }
      }
    }`);
    if (result) {
      return result.data.Helper.SetUserDefaultViewForRegister;
    }
    return false;
  },

  resetUserDefaultViewForRegister: async ({ entityTypeId, entityId, pentityTypeId, pentityId }) => {

    var result = await Graphql(`{
      Helper {
        ResetUserDefaultViewForRegister(entityTypeId:${entityTypeId},entityId:${entityId},
          pentityTypeId:${pentityTypeId},pentityId:${pentityId})
                  {
                    dataJson
                  }
      }
    }`);
    if (result) {
      return result.data.Helper.ResetUserDefaultViewForRegister;
    }
    return false;
  },

  deleteEntity: async ({ entityTypeId, entityId, pentityTypeId, pentityId }) => {

    var result = await Graphql(`{
      Helper {
        DeleteEntity(entityTypeId:${entityTypeId},entityId:${entityId},
          pentityTypeId:${pentityTypeId},pentityId:${pentityId})
                  {
                    dataJson
                  }
      }
    }`);
    if (result) {
      return result.data.Helper.DeleteEntity;
    }
    return false;
  },
  deleteSpecificLink: async ({ linkId }) => {

    var result = await Graphql(`{
      Helper {
        DeleteSpecificLink(linkId:${linkId})
          {
            dataJson
          }
      }
    }`);
    if (result) {
      return result.data.Helper.DeleteEntity;
    }
    return false;
  }

};
export default apis